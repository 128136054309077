import { IPFS_IS_LOADING, ROOT_HAS_LOADED } from "../actions/ipfs";

export const appStates = {
  APP_STARTED: 'APP_STARTED',
  INFRASTRUCTURE_LOADING: 'INFRASTRUCTURE_LOADING',
  INFRASTRUCTURE_READY: 'INFRASTRUCTURE_READY',
};

export function applicationState(state = { pos: appStates.APP_STARTED }, action) {
  switch(action.type) {
    case IPFS_IS_LOADING:
      return { pos: appStates.INFRASTRUCTURE_LOADING };

    case ROOT_HAS_LOADED:
      return { pos: appStates.INFRASTRUCTURE_READY, root: { digdownType: action.digdownType, detail: action.detail } };

    default:
      return state;
  }
}

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createHashHistory();

export default function configureStore(initialState) {
    return createStore(
        rootReducer(history),
        initialState,
        composeWithDevTools(applyMiddleware(
            routerMiddleware(history),
            thunk
        )),
    );
}

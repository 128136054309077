import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { profileWillLoad, profileWillReset } from "../actions/profiles";
import { useParams } from "react-router";

import Feed from "../components/Feed";
import Skeleton from '@material-ui/lab/Skeleton';
import Following from "../components/Following";
import { Typography } from "@material-ui/core";

function ProfilePage({ profile, onLoad, username }) {
  const { username: usernameParam } = useParams();
  if (username === undefined) {
    username = usernameParam;
  }

  // eslint-disable-next-line
  useEffect(() => onLoad(username), [username]);
  if (profile instanceof Error) return <div>{profile.toString()}</div>;

  return (
    <div>
      <Typography variant='h4' component='h1'>{profile.username}</Typography>
      {profile.loading ? <Skeleton width="7em" /> : <Typography variant='body1'>{profile.name}</Typography>}

      <div>{profile.loading ? <Skeleton width="10em"/> : <>Following: <Following usernames={profile.following}/></>}</div>


      {!profile.loading && <Feed chains={[profile.latestPost()]}/>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  const onUnload = () => dispatch(profileWillReset());
  return {
    onLoad: (username) => {
      dispatch(profileWillLoad(username));
      return onUnload;
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

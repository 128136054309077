import { PROFILE_HAS_LOADED, PROFILE_IS_LOADING, PROFILE_NOT_FOUND, PROFILE_WILL_RESET } from "../actions/profiles";

export function profile(state = { loading: true }, action) {
  switch(action.type) {
    case PROFILE_IS_LOADING:
      return { username: action.username, loading: true };

    case PROFILE_HAS_LOADED:
      return action.profile;

    case PROFILE_WILL_RESET:
      return { loading: true };

    case PROFILE_NOT_FOUND:
      return new Error(`Cannot find profile for ${action.username}`);

    default:
      return state;
  }
}

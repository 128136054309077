import React from 'react';
import Link from "../components/Link";
import routes from '../routes';
import Typography from "@material-ui/core/Typography";

function NotFoundPage() {
  return (
    <div>
      <Typography variant='h4' component='h1'>Uh oh.</Typography>
      <Typography>
        This path doesn't exist. Try going <Link href={routes.home}>home</Link>?
      </Typography>
    </div>
  );
}

export default NotFoundPage;

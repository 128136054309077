import React from 'react';
import Markdown from 'markdown-to-jsx';
import VisibilitySensor from 'react-visibility-sensor';
import Skeleton from "@material-ui/lab/Skeleton";
import TimeAgo from 'react-timeago';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import MediaSwipe from "./MediaSwipe";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  card: {
    width: 540,
  },
}));

function Post({ post, onSeen }) {
  const classes = useStyles();

  const PostIcon =  post ? post.username[0] : <PersonIcon />;
  const PostTitle = post ? post.username : <Skeleton />;
  const PostTime = post ? <TimeAgo date={post.postedAt} /> : <Skeleton />;


  return (
    <VisibilitySensor partialVisibility onChange={visible => visible && onSeen()}>
      <Card className={classes.card}>
        <CardHeader avatar={<Avatar>{PostIcon}</Avatar>}
          title={PostTitle}
          subheader={PostTime}
        />
        <CardMedia>
          {post ? <MediaSwipe media={post.media} /> : <Skeleton height="540px"/>}
        </CardMedia>
        <CardContent>
          {post ? (
            <Typography variant="body2" color="textPrimary" component="p"><Markdown>{post.description}</Markdown></Typography>
          ) : <>
            <Skeleton /><Skeleton width='60%'/>
          </>}
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </VisibilitySensor>
  );
}

export default Post;

export const POST_IS_LOADING = 'POST_IS_LOADING';
export const POST_HAS_LOADED = 'POST_HAS_LOADED';
export const POST_WAS_SEEN = 'POST_WAS_SEEN';
export const POSTS_WILL_RESET = 'POSTS_WILL_RESET';

export function postWasSeen(id, nextItems) {
    return (dispatch) => {
        dispatch({
           type: POST_WAS_SEEN,
           id
        });

        const nextPostProm = nextItems[id];
        nextPostProm && dispatch(postWillLoad(nextPostProm));
    };
}

export function postWillLoad(postPromise) {
    return (dispatch) => {
        dispatch({
            type: POST_IS_LOADING,
            id: postPromise.id
        });
        postPromise.then(post => dispatch({
            type: POST_HAS_LOADED,
            id: post.id,
            post
        }));
    };
}

export function postsWillReset() {
    return {
        type: POSTS_WILL_RESET
    };
}

import React from 'react';
import Link from "../components/Link";
import routes from '../routes';
import { Typography } from "@material-ui/core";

function IndexPage() {
  return (
    <>
      <Typography variant='h1'>Digdown</Typography>
      <Link href={routes.profile('jp.digdown.app')}>jp<small>.digdown.app</small></Link><br />
      <Link href={routes.profile('yvette.digdown.app')}>yvette<small>.digdown.app</small></Link><br />
    </>
  );
}

export default IndexPage;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { applicationState } from './applicationState';
import { profile } from './profile';
import { stream } from "./stream";

export default (history) => combineReducers({
    router: connectRouter(history),
    applicationState,
    profile,
    stream,
});
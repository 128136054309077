import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter, push } from 'connected-react-router';

import ProfilePage from './pages/ProfilePage';
import IndexPage from "./pages/IndexPage";
import NotFoundPage from "./pages/NotFoundPage";
import InfrastructureLoadingPage from "./pages/InfrastructureLoadingPage";

import routes from './routes';

import { ipfsShouldLoad } from "./actions/ipfs";
import { appStates } from './reducers/applicationState';
import { history } from './store/configureStore';
import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

function App({ whenReady, onLoad, rootComponent, goHome }) {
  useEffect(onLoad, []);
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title} onClick={goHome}>DigDown</Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Toolbar />

      <Container fixed>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path={routes.home} children={whenReady(rootComponent)} />}
            <Route path={routes.profile(':username')} children={whenReady(<ProfilePage />)} />}
            <Route children={<NotFoundPage />} />
          </Switch>
        </ConnectedRouter>
      </Container>
    </>
  );
}

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const mapStateToProps = (state) => {
  let rootComponent;
  const root = state.applicationState.root;
  switch (root && root.digdownType) {
    case 'profile':
      rootComponent = <ProfilePage username={root.detail}/>
      break;
    default:
      rootComponent = <IndexPage />;
  }

  const ready = state.applicationState.pos === appStates.INFRASTRUCTURE_READY
  const whenReady = (page) => ready ? page : <InfrastructureLoadingPage/>;

  return { whenReady, rootComponent };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => dispatch(ipfsShouldLoad()),
    goHome: () => dispatch(push(routes.home)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Post from '../components/Post';
import { postsWillReset, postWasSeen, postWillLoad } from "../actions/posts";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function Feed({ stream, onLoad, onPostSeen }) {
  useEffect(onLoad, []);

  return (
    <Grid container spacing={2}>
      {stream.posts.map((post) => (
        <Grid key={post.id} item xs={12}>
          <Post post={ post } onSeen={() => onPostSeen(post.id, stream.unloadedPosts)}/>
        </Grid>
      ))}
      {stream.endOfStream && <Grid key='end' item xs={12}><Typography align='center' component='div'>∞</Typography></Grid>}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.stream,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const onUnload = () => dispatch(postsWillReset());

  return {
    onLoad: () => {
      ownProps.chains.forEach(postProm => dispatch(postWillLoad(postProm)));
      return onUnload;
    },
    onPostSeen: (id, unloadedPosts) => dispatch(postWasSeen(id, unloadedPosts)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);

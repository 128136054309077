import React, { useState, useRef } from 'react';
import ImagePromise from './ImagePromise';
import "./MediaSwipe.css";

const width = 540;

function MediaSwipe({ media }) {
  const maxLength = media.length - 1;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [movement, setMovement] = useState(0);
  const [transitionDuration, setTransitionDuration] = useState(null);
  const [height, setHeight] = useState('auto');

  let lastTouch = 0;
  const handleTouchStart = (e) => {
    lastTouch = e.nativeEvent.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const delta = lastTouch - e.nativeEvent.touches[0].clientX;
    lastTouch = e.nativeEvent.touches[0].clientX;
    handleMovement(delta);
  };

  const handleTouchEnd = () => {
    lastTouch = 0;
    handleMovementEnd();
  };

  // let wheelTimeout;
  const handleWheel = (e) => {
    // FIXME: Scrollwheel is borked right now
    // clearTimeout(wheelTimeout);
    // handleMovement(e.deltaX);
    // wheelTimeout = setTimeout(() => handleMovementEnd(), 100);
  };

  const handleMovement = (delta) => {
    let nextMovement = movement + delta;

    if (nextMovement < 0) {
      nextMovement = 0;
    }

    if (nextMovement > maxLength * width) {
      nextMovement = maxLength * width;
    }

    setMovement(nextMovement);
    setTransitionDuration('0s');
  };

  const handleMovementEnd = () => {
    const endPosition = movement / width;
    const endPartial = endPosition % 1;
    const endingIndex = endPosition - endPartial;
    const deltaInteger = endingIndex - currentIndex;

    let nextIndex = endingIndex;

    if (deltaInteger >= 0) {
      if (endPartial >= 0.1) {
        nextIndex += 1;
      }
    } else if (deltaInteger < 0) {
      nextIndex = currentIndex - Math.abs(deltaInteger);
      if (endPartial > 0.9) {
        nextIndex += 1;
      }
    }

    transitionTo(nextIndex, Math.min(0.5, 1 - Math.abs(endPartial)));
  };

  const setHeightFromItem = (mediaItem) => {
    const newHeight = mediaItem.clientHeight * width / mediaItem.clientWidth;
    setHeight(newHeight);
  };

  const container = useRef(null);
  const transitionTo = (index, duration) => {
    setCurrentIndex(index);
    setMovement(index * width);
    setTransitionDuration(`${duration}s`);

    setHeightFromItem(container.current.children[index].firstElementChild);
    setTimeout(() => {
      setTransitionDuration("0s");
    }, duration * 100);
  };

  const containerStyles = {
    transform: `translateX(${movement * -1}px)`,
    transitionDuration,
    height,
  };

  return (
    <div
      className="MediaSwipe-root"
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <ol className="MediaSwipe-container" style={containerStyles} ref={container}>
        {media.map((itemPromise, i) => <li key={i}>
          <ImagePromise
            promise={itemPromise()}
            alt={`Image ${i + 1} of ${media.length} from this post`}
          />
        </li>)}
      </ol>
      {movement !== 0 && (
        <button
          className="back move"
          onClick={() => transitionTo(currentIndex - 1, 0.5)}
        >
          ←
        </button>
      )}
      {movement !== (maxLength * width) && (
        <button
          className="next move"
          onClick={() => transitionTo(currentIndex + 1, 0.5)}
        >
          →
        </button>
      )}
    </div>
  );
}

export default MediaSwipe;

import React, { useState, useEffect } from 'react';

function ImagePromise({ promise, alt }) {
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    promise.then(url => setImageURL(url))
  }, [promise]);

  if (!imageURL) return <div width="100%"/>;

  return (
    <img src={imageURL} alt={alt}/>
  );
}

export default ImagePromise;

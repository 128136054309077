import getIpfs from 'ipfs-provider';
import CID from 'cids';
import localStorage from 'store';

let ipfs;
const version = "0.1.0";

const urlUtils = window.URL || window.webkitURL;

function dataToURL(data) {
  return urlUtils.createObjectURL(new Blob([data]));
}

// This method exists to deal with the fact the window.IPFS returns objects, not CID objects, from DAG queries.
function cidify(cidOrArrHashObj) {
  if (Array.isArray(cidOrArrHashObj))
    cidOrArrHashObj = cidOrArrHashObj[0];

  if (cidOrArrHashObj && cidOrArrHashObj.hash) {
    const cid = (new CID(cidOrArrHashObj.version, cidOrArrHashObj.codec, Buffer.from(cidOrArrHashObj.hash.data)));
    return cid.toV1()
  } else {
    return cidOrArrHashObj;
  }
}

class Post {
  constructor(id, {username, media, description, postedAt, previousPost}) {
    this.id = id;
    this.username = username;
    this.media = media.map(cid => () => ipfs.cat(cidify(cid)).then(dataToURL));
    this.description = description || "";
    this.postedAt = new Date(postedAt * 1000);
    this.previousPost = previousPost && Post.load(username, cidify(previousPost));
  }

  destructor() {
    this.media.forEach(urlUtils.revokeObjectURL);
  }
}

Post.load = (checkUsername, postCid) => {
  const hash = postCid.toBaseEncodedString();

  async function exec(checkUsername, hash) {
    // FIXME: While using the window IPFS the following command isn't resolving
    const postNode = await ipfs.dag.get(hash);
    const post = postNode.value;

    if (post.digdown !== `post/${version}`) {
      throw new Error(`Cannot process this version of the post: ${post.digdown}`);
    }

    if (checkUsername && post.username !== checkUsername) {
      throw new Error(`Post has a mismatching username: ${post.username} should be ${checkUsername}`);
    }

    return new Post(hash, post);
  }

  const prom = exec(checkUsername, hash);
  prom.id = hash;
  return prom;
};

class Profile {
  constructor(rootHash, {username, name, bio, latestPost, following}) {
    this.rootHash = rootHash;
    this._rootHashChanged = false;

    this.username = username;
    this.name = name;
    this.bio = bio;
    this._latestPostCid = cidify(latestPost);
    this.latestPost = () => this._latestPostCid && Post.load(username, this._latestPostCid);
    this.following = following || [];
  }

  async uploadPost(description, addables, postedAt = new Date()) {
    // TODO: media validation?

    const emptyRootProm = ipfs.object.patch.rmLink(this.rootHash, { name: '.digdown' });
    const media = await Promise.all(addables.map(addable => {
      return ipfs.addFromURL(addable).then(cidify);
    }));

    this._latestPostCid = await ipfs.dag.put({
      digdown: `post/${version}`,
      username: this.username,
      media,
      description,
      postedAt: postedAt.getTime() / 1000,
      previousPost: this._latestPostCid,
    });

    const profile = await ipfs.dag.put({
      digdown: `profile/${version}`,
      username: this.username,
      name: this.name,
      bio: this.bio,
      latestPost: this._latestPostCid,
    });

    const emptyRoot = await emptyRootProm;
    const newRoot = await ipfs.object.patch.addLink(emptyRoot.toBaseEncodedString(),
      { name: '.digdown', size: profile.size, cid: profile },
    );
    this.rootHash = newRoot.toBaseEncodedString();
    this._rootHashChanged = true;
  }

  async publish() {
    if (!this._rootHashChanged) return;
    // TODO: Switch this to using NS
    await ipfs.name.publish(this.rootHash, {
      resolve: false,
      lifetime: "168h",
      ttl: "15m",
      key: "digdown"
    });
    this._rootHashChanged = false;
  }
}

const usernameLookupTTL = 5 * 60 * 1000;

Profile.loadUsername = async (username) => {
  if (username.substr(0, 2) === "Qm") {
    return Profile.load(username);
  }

  const now = (new Date()).getTime();
  const key = `profile:${username}`;
  let usernameLookup = localStorage.get(key);

  if (!usernameLookup || usernameLookup.staleAt <= now) {
    const hash = await ipfs.name.resolve(`/ipns/${usernameToSubdomain(username)}`)
      .then(path => path.substr(6));

    usernameLookup = {
      hash,
      staleAt: now + usernameLookupTTL,
    };

    localStorage.set(key, usernameLookup);
  }

  return Profile.load(usernameLookup.hash);
};

Profile.load = async (hash) => {
  const profileNode = await ipfs.dag.get(`${hash}/.digdown`);
  const profile = profileNode.value;

  if (profile.digdown !== `profile/${version}`) {
    throw new Error(`Cannot process this version of the Profile: ${profile.digdown}`);
  }

  return new Profile(hash, profile)
};

function usernameToSubdomain(username) {
  if (!username.includes("digdown")) {
    return `digdown.${username}`
  }
  return username
}

export default Profile;

export async function initIpfs() {
  let provider;
  ({ ipfs, provider } = await getIpfs({
    tryWebExt: true,
    tryWindow: true,
    tryApi: true,
    tryJsIpfs: true,
    getJsIpfs: () => { return require('ipfs') },
    jsIpfsOpts: {silent: false},
    permissions: { commands: ['dag.get', 'cat', 'object.patch.rmLink', 'object.patch.addLink', 'add'] },
  }));

  console.log("Using:", provider);

  try {
    const view = await fetch('.digdown/usernamefile').then(res => res.text());
    const [digdownType, version, detail] = view.split(/[:/]/);
    return {digdownType, version, detail}
  } catch(e) {
    console.error(e);
    return false;
  }
}

import React, { useState } from "react";
import { connect } from 'react-redux';
import { push } from 'connected-react-router'

import Link from "./Link";
import routes from "../routes";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PersonIcon from '@material-ui/icons/Person';
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

function Following({ usernames, goToProfile }) {
  const [followingListOpen, setFollowingListOpen] = useState(false);

  if (usernames.length === 0) return "no-one, yet.";

  const [firstUsername, ...otherUsernames] = usernames;
  const hasOthers = otherUsernames.length > 0;

  const onClose = () => setFollowingListOpen(false);

  return (
    <>
      <Link href={ routes.profile(firstUsername) }>{ firstUsername }</Link>
      { hasOthers && <> and <a onClick={ () => {setFollowingListOpen(true)} }>
          { otherUsernames.length } { otherUsernames.length === 1 ? 'other' : 'others' }
        </a>
      </> }

      <Dialog open={followingListOpen} onClose={onClose}>
        <DialogTitle>Following</DialogTitle>
        <List>
          {usernames.map(username => (
            <ListItem button onClick={() => { onClose(); goToProfile(username) }} key={username}>
              <ListItemAvatar>
                <Avatar><PersonIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText primary={username} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToProfile: (username) => dispatch(push(routes.profile(username))),
  };
};

export default connect(null, mapDispatchToProps)(Following);
import React from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";

function InfrastructureLoadingPage() {

  return (
    <div>
      <Typography variant='h4' component='h1'>Loading IPFS</Typography>
      <Typography>
        We're connecting you to the Interplanetary Filesystem, please wait…
      </Typography>
    </div>
  );
}

export default connect(null, null)(InfrastructureLoadingPage);

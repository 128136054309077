import { POST_WAS_SEEN, POST_HAS_LOADED, POSTS_WILL_RESET } from "../actions/posts";

function emptyPosts() {
  return {
    posts: [],
    unloadedPosts: {}, // post id => next post promise
    endOfStream: false,
  }
}

function unloadedPostsLeft(unloadedPosts) {
  return Object.keys(unloadedPosts).length === 0;
}

export function stream(state = emptyPosts(), action) {
  switch(action.type) {
    case POSTS_WILL_RESET:
      state.posts.forEach(post => post.destructor());
      return emptyPosts();

    case POST_HAS_LOADED:
      const posts = [
        ...state.posts,
        action.post,
      ];

      const prevPost = action.post.previousPost;
      const unloadedPosts = Object.assign({}, state.unloadedPosts);
      if (prevPost !== null) unloadedPosts[action.post.id] = prevPost;

      return {
        ...state,
        posts,
        unloadedPosts,
        endOfStream: unloadedPostsLeft(unloadedPosts),
      };

    case POST_WAS_SEEN:
      const newUnloadedPosts = Object.assign({}, state.unloadedPosts);
      delete newUnloadedPosts[action.id];

      console.log();

      return {
        ...state,
        unloadedPosts: newUnloadedPosts,
        endOfStream: unloadedPostsLeft(newUnloadedPosts),
      };

    default:
      return state;
  }
}

import Profile from '../db/ipfs';

export const PROFILE_WILL_RESET = 'PROFILE_WILL_RESET';
export const PROFILE_HAS_LOADED = 'PROFILE_HAS_LOADED';
export const PROFILE_IS_LOADING = 'PROFILE_IS_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';

export function profileWillReset() {
    return { type: PROFILE_WILL_RESET };
}

export function profileWillLoad(username) {
    return (dispatch) => {
        dispatch({ type: PROFILE_IS_LOADING, username });
        Profile.loadUsername(username)
          .then(profile => dispatch(profileHasLoaded(profile)))
          .catch((e) => {
              console.error(e);
              dispatch(profileNotFound(username));
          });
    }
}

export function profileHasLoaded(profile) {
    return {
        type: PROFILE_HAS_LOADED,
        profile
    };
}

export function profileNotFound(username) {
    return {
        type: PROFILE_NOT_FOUND,
        username
    };
}

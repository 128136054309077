import { initIpfs } from '../db/ipfs';

export const IPFS_IS_LOADING = 'IPFS_IS_LOADING';
export const ROOT_HAS_LOADED = 'ROOT_HAS_LOADED';

export function ipfsShouldLoad() {
    return (dispatch) => {
        dispatch({ type: IPFS_IS_LOADING });
        initIpfs().then(({digdownType, detail}) => {
          dispatch(rootHasLoaded({ digdownType, detail }))
        });
    }
}

export function rootHasLoaded({ digdownType, detail }) {
  return {
    type: ROOT_HAS_LOADED,
    digdownType,
    detail,
  };
}

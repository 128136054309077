import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

function Link({ go, href, children }) {
  return (
    <a href={`#${href}`} onClick={go(href)}>{children}</a>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    go: (href) => (e) => {
      e.preventDefault();
      dispatch(push(href));
    },
  };
};

export default connect(null, mapDispatchToProps)(Link);
